import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_i = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - I'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>I</h2>
     <LatQuotesIntro />
    
     <p><b>Ibidem (Ib.)</b> - In the same place. (in a book)</p>
<p><b>Id certum est quod certum reddi potest</b> - That is certain that can be made certain</p>
<p><b>Id est (i.e.)</b> - That is to say</p>
<p><b>Idem quod (i.q.)</b> - The same as</p>
<p><b>Idem</b> - The same</p>
<p><b>Iesus Nazarenus Rex Iudaeorum (INRI)</b> - Jesus of Nazareth, King of the Jews</p>
<p><b>Ignis aurum probat, miseria fortes viros</b> - Life is not a bowl of cherries, or, literally, Fire tests gold; adversity tests strong men</p>
<p><b>Ignis fatuus</b> - Foolish fire</p>
<p><b>Ignorantia juris neminem excusat</b> - Ignorance of the law excuses no one</p>
<p><b>Ignoratio elenchi</b> - An ignorance of proof</p>
<p><b>Ignotus (ign.)</b> - Unknown</p>
<p><b>Ille dolet vere, qui sine teste dolet</b> - He mourns honestly who mourns without witnesses. (Martialis)</p>
<p><b>Ille mi par esse deo videtur</b> - He seems to me to be equal to a god. (Catullus)</p>
<p><b>Imitatores, servum pecus!</b> - Imitators, you slavish crowd! (Horace)</p>
<p><b>Imperator/Imperatrix (Imp.)</b> - Emperor/Empress</p>
<p><b>Imperium et libertas</b> - Empire and liberty. (Cicero)</p>
<p><b>Imperium in imperio</b> - An empire within an empire, i.e. A fifth column, a group of people within an nation's territory who owe allegiance to some other leader</p>
<p><b>Imperium</b> - Absolute power</p>
<p><b>Impossibilium nulla obligatio est</b> - Nobody has any obligation to the impossible. (Corpus Iuris Civilis)</p>
<p><b>Imprimatur</b> - Let it be printed</p>
<p><b>Imprimis</b> - In first place</p>
<p><b>In absentia</b> - In one's absence</p>
<p><b>In actu</b> - In practice</p>
<p><b>In aere aedificare</b> - Build (castles) in the air. (St. Augustine)</p>
<p><b>In aeternum</b> - For eternity</p>
<p><b>In alio pediculum, in te ricinum non vides</b> - You see a louse on someone else, but not a tick on yourself. (Petronius)</p>
<p><b>In articulo mortis</b> - At the moment of death</p>
<p><b>In banco</b> - On the bench</p>
<p><b>In camera</b> - In private chamber</p>
<p><b>In capite</b> - In chief</p>
<p><b>In cauda venenum</b> - In the tail [is the] poison. Watch out for what you don't see</p>
<p><b>In curia</b> - In court</p>
<p><b>In distans</b> - At a distance</p>
<p><b>In dubiis non est agendum</b> - In dubious cases, you should not act</p>
<p><b>In dubio pro reo</b> - In doubt in favor of the accused. If there is a doubt about guiltiness, the judgement has to be in favour of the accused</p>
<p><b>In dubio</b> - In doubt</p>
<p><b>In esse</b> - In existence</p>
<p><b>In excelsis</b> - In the highest</p>
<p><b>In extenso</b> - At full length</p>
<p><b>In extremis</b> - In extremity</p>
<p><b>In fine</b> - At the end</p>
<p><b>In flagrante delicto</b> - In the very act of committing an offence</p>
<p><b>In forma pauperis</b> - In the form of a poor person; in a humble or abject manner</p>
<p><b>In futuro</b> - In the future</p>
<p><b>In gremio legis</b> - In the protection of the law</p>
<p><b>In his ordo est ordinem non servare</b> - In this case the only rule is not obeying any rules</p>
<p><b>In hoc signo vinces</b> - In this sign, you will be victorious. (Eusebios)</p>
<p><b>In infinitum</b> - To infinity; without end</p>
<p><b>In libris libertas</b> - In books (there is) freedom</p>
<p><b>In limine</b> - On the threshold, at the very outset</p>
<p><b>In loco parentis</b> - In the place of a parent</p>
<p><b>In loco</b> - In the place of</p>
<p><b>In magnis et voluisse sat est</b> - To once have wanted is enough in great deeds. (Propertius)</p>
<p><b>In media res</b> - In or into the middle of a sequence of events. (Horace)</p>
<p><b>In medias res</b> - Into the midst of things</p>
<p><b>In medio stat virtus</b> - Virtue stands in the middle. Virtue is in the moderate, not the extreme position. (Horace)</p>
<p><b>In medio tutissimus ibis</b> - In the middle of things you will go most safe. (Ovid)</p>
<p><b>In memoriam</b> - In memory (of)</p>
<p><b>In necessariis unitas, in dubiis libertas, in omnibus caritas</b> - In necessary things unity, in doubtful things liberty, in all things charity</p>
<p><b>In nomine Domini</b> - In the name of the Lord</p>
<p><b>In nomine Patris et Filii et Spiritus Santi</b> - In the name of the Father and of the Son and of the Holy Spirit</p>
<p><b>In nubibus</b> - In the clouds</p>
<p><b>In nuce</b> - In a nutshell</p>
<p><b>In omnia paratus</b> - Prepared for all things</p>
<p><b>In ovo</b> - In the egg</p>
<p><b>In pace</b> - In peace</p>
<p><b>In pace, ut sapiens, aptarit idonea bello</b> - In peace, like a wise man, he appropriately prepares for war</p>
<p><b>In pari materia</b> - Of like kind</p>
<p><b>In partibus infidelium</b> - In parts inhabited by unbelievers</p>
<p><b>In parvo</b> - In miniature</p>
<p><b>In perpetuum</b> - For ever</p>
<p><b>In personam</b> - Against the person</p>
<p><b>In pleno</b> - In full</p>
<p><b>In pontificalibus</b> - In the proper vestments of a pope or cardinal</p>
<p><b>in posse</b> - In possibility</p>
<p><b>In posterum</b> - Till the next day</p>
<p><b>In praesenti</b> - At the present time</p>
<p><b>In principio</b> - In the beginning</p>
<p><b>In propria persona</b> - In person</p>
<p><b>In quaestione versare</b> - To be under investigation</p>
<p><b>In re</b> - Refering to</p>
<p><b>In rem</b> - Against the matter (property)</p>
<p><b>In rerum natura</b> - In the nature of things</p>
<p><b>In saecula saeculorum</b> - For ages of ages forever</p>
<p><b>In se</b> - In itself</p>
<p><b>In silvam ne ligna feras</b> - Don't carry logs into the forest. (Horace)</p>
<p><b>In situ</b> - In position</p>
<p><b>In specie</b> - In kind; (a) in its own form and not in an equivalent (b) in coins and not in paper money</p>
<p><b>In spiritu et veritate</b> - In spirit and truth. (Versio Vulgata)</p>
<p><b>In statu quo</b> - In the same state</p>
<p><b>In terrorem</b> - As a warning; in order to terrify others</p>
<p><b>In totidem verbis</b> - In so many words</p>
<p><b>In toto</b> - As a whole, absolutely, Completely</p>
<p><b>In transitu</b> - In passing, on the way</p>
<p><b>In usu</b> - In use</p>
<p><b>In utero</b> - In the womb</p>
<p><b>In vacuo</b> - In a vacuum or empty space</p>
<p><b>In vinculis etiam audax</b> - In chains yet still bold (free)</p>
<p><b>In vino veritas</b> - The truth is in wine. (A drunk person tells the truth)</p>
<p><b>In virtute sunt multi ascensus</b> - There are many degrees in excellence. (Cicero)</p>
<p><b>In vitro</b> - In a test tube (literally glass)</p>
<p><b>In vivo</b> - In the living (thing)</p>
<p><b>Incipit</b> - Begin here</p>
<p><b>Incredibile dictu</b> - Incredible to say</p>
<p><b>Index librorum prohibitorum</b> - Official list of forbidden books not to be read by Catholics</p>
<p><b>Indulgentiam quaeso</b> - I ask your indulgence</p>
<p><b>Infinitus est numerus stultorum</b> - Infinite is the number of fools</p>
<p><b>Infra dignitatem (dig.)</b> - Undignified; beneath one's dignity</p>
<p><b>Infra</b> - Below, underneath</p>
<p><b>Inhumanitas omni aetate molesta est</b> - Inhumanity is harmful in every age. (Cicero)</p>
<p><b>Iniqua nunquam regna perpetuo manent</b> - Stern masters do not reign long. (Seneca Philosophus)</p>
<p><b>Iniuria non excusat iniuriam</b> - One wrong does not justify another</p>
<p><b>Insanabile cacoethes scribendi</b> - An incurable passion to write. (Juvenal)</p>
<p><b>Insculpsit</b> - He/she engraved it</p>
<p><b>Integer vitae scelerisque purus</b> - Blameless of life and free from crime</p>
<p><b>Intellectum valde amat</b> - Love the intellect strongly. (St. Augustine)</p>
<p><b>Intelligenti pauca</b> - Few words suffice for he who understands</p>
<p><b>Intelligo me intelligere</b> - I understand that I understand. (St. Augustine)</p>
<p><b>Inter alia</b> - Among other things</p>
<p><b>Inter alios</b> - Amongst other people</p>
<p><b>Inter arma silent leges</b> - In time of war, laws are silent</p>
<p><b>Inter caecos regnat strabo</b> - Among blinds the squinting rules. (Erasmus)</p>
<p><b>Inter canum et lupum</b> - Between a dog and a wolf</p>
<p><b>Inter nos</b> - Between ourselves</p>
<p><b>Inter partes</b> - Made between two parties</p>
<p><b>Inter se</b> - Between themselves</p>
<p><b>Inter spem et metum</b> - Between hope and fear</p>
<p><b>Inter vivos</b> - Between living (people)</p>
<p><b>Interfice errorem, diligere errantem</b> - Kill the sin, love the sinner. (St. Augustine)</p>
<p><b>Interregnum</b> - Period between rules anarchy, lawlessnes</p>
<p><b>Intra muros</b> - Within the walls</p>
<p><b>Intra vires</b> - Within the power</p>
<p><b>Inventas vitam iuvat excoluisse per artes</b> - Let us improve life through science and art. (Vergil)</p>
<p><b>Ipsa quidem pretium virtus sibi</b> - Virtue is its own reward</p>
<p><b>Ipsa scientia potestas est</b> - Knowledge itself is power. (Bacon)</p>
<p><b>Ipsissima verba</b> - The exact words</p>
<p><b>Ipso facto</b> - By that very fact</p>
<p><b>Ipso iure</b> - By operation of the law</p>
<p><b>Ipsus dixit</b> - He himself said it</p>
<p><b>Ira furor brevis est</b> - Anger is a brief insanity. (Horace)</p>
<p><b>Ire fortiter quo nemo ante iit</b> - To boldly go where no man has gone before. (Star Trek)</p>
<p><b>Ita est</b> - Yes./It is so</p>
<p><b>Ite, missa est</b> - Go, the Mass is finished</p>
<p><b>Iubilate Deo</b> - Rejoice in God</p>
<p><b>Iunctis viribus</b> - By united efforts</p>
<p><b>Iure divino</b> - By divine law</p>
<p><b>Iure humano</b> - By human law</p>
<p><b>Ius civile</b> - Civil law</p>
<p><b>Ius est ars boni et aequi</b> - Law is the art of the good and the just</p>
<p><b>Ius gentium</b> - Right of tribes law of nations</p>
<p><b>Ius gentium</b> - The law of nations</p>
<p><b>Ius primae noctis</b> - The right of the first night</p>
<p><b>Iustitia omnibus</b> - Justice for all</p>



   </Layout>
  )
}

export default LatQuotes_i
